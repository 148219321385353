export default {
    navTitle: 'Enterprise Search',
    history_text: 'History',
    profile_text: 'Profile',
    preferences_text: 'Preferences',
    user_name: 'User Name', 
    signout_text: 'Sign Out',
    search_text : "Search",
    loadingText: 'Access all your data in any format, from anywhere inside your company - in databases, document management systems, wherever.',
    synonyms_heading: 'SYNONYMS',
    semantics_heading: 'SEMANTICS',  
    date_heading: 'Date',
    about_text: 'About',
    results_text:"results",
    sort_by_text: 'Sort By',
    group_by_text: 'Group By',
    relevance_list_text: 'Relevance',
    ascending_order_text: 'Ascending order',
    descending_order_text: 'Descending order',
    explore_text: 'EXPLORE',
    each_documnet_title: '(PC) Forecast Database (3 results)',
    publish_text: 'Published',
    source_text: 'Source',
    file_text: 'File',
    nodata_text_block_text: "Your search didn't match any documents",
    nodata_text_first_line: 'Try different keywords',
    nodata_text_second_line: 'Make sure that all words are spelled correctly',
    nodata_match_text:'No matches for', 
    box_loading_text: 'Loading...',
    field_search_text: 'Field Search',
    fieldsearch_button_text: 'Search',
    search_box_text: 'Search',
    advanced_search_text: 'Advanced Search',
    clear_all_text: 'Clear all',
    history_id_text: 'ID',
    history_query_text: 'Query',
    history_hits_text: 'Hits',
    history_date_text: 'Date', 
    history_run_text: 'Run', 
    history_delete_text: 'Delete', 
    semantics_title_text:'Semantics List',
    synonyms_text:'Synonyms List',
    source_heading: 'Source',
    filter_heading: 'FILTERS',
    project_title_text: 'Projects',
    create_project_text:'Create New',
    add_source_text:'Add Data Source',
    add_service_text:'Add Service',
    no_project_text_line_one:'Hey, looks like you have no projects for given term.',
    no_project_text_line_second: "let's",
    add_data_source_line_one:"Hey, looks like you haven't added data yet.",
    add_data_source_line_second: "Add data files at",
    no_access_text_line_one:"Hey, looks like project not available ",
    no_access_text_line_second: "OR Do't have access to this Project",
    create_new_project_text: 'Create New Project', 
    edit_project_text: 'Edit Project',
    delete_project_text: 'Delete Project',
    share_project_text: 'Share Project',
    back_to_home_text:'Back',
    mindmap_text: "Mindmap",
    synonyms_list_text:'Synonyms',
    semantics_list_text: 'Semantics',
    taxonomy_list_text:'Taxonomy',
    taxonomy_title:'TAXONOMY',
    semantics_title: 'SYNONYMS & SEMANTICS',
    project_name_text: "Project Name",
    project_files_text:'Files found',
    project_created_on_text: 'Created On',
    project_modified_date_text: 'Updated On',
    project_list_text:"Projects list",
    project_status_text: 'Status',
    project_action_text: 'Action',
    new_project_text: 'Create New Project',
    create_button_text: 'Create', 
    update_button_text: 'Update', 
    share_button_text: 'Share',
    delete_button_text: 'Yes',
    project_description_text:'Description',
    eou_text:"Eou",
    source_files_text: 'Source',
    paste_files_text: 'URL',
    paste_placeholder: 'Paste URL here...',
    es_or_text: 'OR',
    search_project: 'Search Project',
    custom_field_text:'Custom Fields',
    add_document_text:'Add Document',
    patent_concepts:'Concepts',
    custom_fields_title: 'Select Custom Fields',
    custom_search_placeholder: 'Search',
    inventor_name_text: 'Inventor Name',
    inventor_company_text: 'Company',
    inventor_files_text: 'Files found',
    inventor_area_text: 'Top Areas',
    inventor_co_author_text: 'co-author',
    synonyms_title_text: 'Synonyms',
    semantics_text: 'Semantics',
    filter_fields_title: 'Select Filter fields',
    connected_source_text: 'Connected Data Source',
    connected_services_text:'Connected Services',
    connected_path_text: 'Path',
    connected_date_text: 'Connected on',
    connected_status_text: 'Status',
    connected_action_text: 'Action',
    add_data_source_text: 'Add Data Source',
    add_button_text: 'Add',
    update_source_text: "Update",
    source_details_placeholder: 'Search files here...',
    all_sources_text: 'All Sources',
    connectors_list_text: 'Connectors',
    list_of_files_text: 'List of files', 
    connector_placeholder: 'Search data source',
    data_source_text: 'Please Select a “data source” from the list',
    wordform_text: 'word forms',
    taxonomy_not_found_text: 'Taxonomy Data Not Found',
    semanitcs_not_found_text: 'Semantics Data Not Found',
    synonyms_not_found_text: 'Synonyms Data Not Found',
    help_text: "Help",
    preferences_field_name : "Field name",
    preferences_filters: "Filters",
    preferences_filters_list:"Filters List",
    preferences_details: "Details",
    preferences_list: "List",
    preferences_tree: "Tree",
    preferences_group_field: "Group Field",
    preferences_default_group: "Default Group",
    preferences_group: "Group",
    preferences_editable: "Edit",
    preferences_edit_type:"Edit Type",
    preferences_import_editable: "Import Edit",
    preferences_patent_field: "Patent Field",
    preferences_html_field:"HTML Field",
    preferences_searchable: "Search",
    preferences_available_list:"Available List",
    preferences_auto_complete: "Auto Complete",
    preferences_field_type: "Type",
    preferences_ui_type: "UI Type",
    preferences_exp_src: "Export Source",
    preferences_sort_by:"Sort By",
    preferences_id_field:"Id Field",
    preferences_export_fields: "Export Field",
    preferences_group_sort: "Group Sort",
    update_preferences_text: "Update Preferences",
    update_settings_text: "Update Settings",
    update_group_sort: "Update Group Sort",
    update_config_text:"Update Configuration",
    setup_config_text:"Setup Configuration",
    update_auto_suggetions: "Update Suggestions",
    preferences_ui_field: "UI Field",
    preferences_field_def: "UI Label",
    claim_chart_list: "Claim Chart",
    export_to_Image: "Export To Image",
    export_to_csv_data: "Export To CSV Data",
    xaxis_text: "X-Axis",
    yaxis_text:"Y-Axis",
    chart_type_text: "Chart Type",
    select_chart_type_text: "Select Chart Type",
    create_chart_text: "Create Chart",
    apply_text: "Apply",
    more_text: "more..",
    select_template: "Select Template",
    copy_chart_links_to_share_text : "Copy this chart links to share",
    no_data_available_text: "No Data Available",
    delete_from_session_text :"Delete from Session",
    save_template_text: "Save to Template",
    create_new_text:'Create New Chart',
    selected_tags_text: "Selected Tags",
    dashboard_nav_text: "Dashboards",
    download_nav_text: "Downloads",
    project_nav_text: "Projects",
    history_nav_text: "History",
    alert_nav_text: "Alerts",
    help_nav_text: "Help",
    field_search_text: "Field Search",
    refine_text: "Refine your search",
    analytics_title_text: "Analytics",
    document_title_text: "Documents",
    wordforms_text: "Wordforms",
    semantics_text: "Semantics",
    synonyms_text: "Synonyms",
    highlight_text: "Highlight",
    save_to_workfile_text :"Save to Workfile",
    publications_text: "Publications",
    applications_text: "Applications",
    families_text: "Families",
    custom_fields_text: "Custom Fields",
    export_text : "Export",
    expand_text: "Expand",
    export_info_text : "Info",
    add_more_filters_text : "Add more filters",
    apply_all_text: "Apply All",
    login_text : "Login",
    search_preferences : "Search Preferences",
    user_management :"User Management",
    sign_out_text : "Sign Out",
    enter_keyword_text: "Enter Keyword",
    enter_group_name_text: "Enter Group Name",
    add_button_text: "Add",
    save_changes_button_text: "Save Changes",
    new_group_text : "New Group",
    enter_keywords_text: "Enter Keywords",
    each_new_line_text: "*Each new line will be considered as a word",
    save_button_text : "Save",
    cancel_button_text : "Cancel",
    back_button_text: "Back",
    clear_button_text : "Clear",
    no_hidden_citations_found_text : "No Hidden Citations Found ",
    wildcard_search_text:"Wildcard Search",
    i_text : "i",
    total_text: "Total",
    more_text: "more..",
    select_template_text : "Select Template",
    no_data_available_text: "No Data Available",
    save_template_text: "Save to Template",
    chart_type_text:  "Chart type",
    customize_analytics_text: "Customize Analytics",
    dolcera_templates_text: "Dolcera Templates",
    user_templates_text: "User Templates",
    new_template_text: "New Template",
    template_name_text: "Template Name",
    save_template_text: "Save Template",
    do_you_want_to_delete_the_chart_text: "Do you want to delete the chart",
    from_a_template_text: "from a template ?",
    do_you_want_to_delete_the_template_text : "Do you want to delete the template?",
    load_template_text : "Load Template",
    save_chart_text : "Save Chart",
    reduce_by_text : "Reduce By",
    missing_patents_text : "Missing Patents",
    patents_in_query_text: "Patents in query",
    replace_with_text: "Replace with",
    semantics_text: "Semantics",
    create_new_chart_text: "Create New",
    synonyms_text: "Synonyms",
    wordforms_heading_text: "Wordforms",
    copy_chart_links_to_share_text : "Copy this chart links to share",
    to_text: "To",
    related_concepts_text: "Related Concepts",
    reduce_by_full_text: "Reduce by application/family may have 1 percent error rate",
    reduce_by_is_allowed_only_for_less_than_text : "Reduce by is allowed only for less than",
    patents_ref_text: "patents",
    family_text : "Family",
    application_text: "Application",
    patents_title_text: "Patents",
    reduce_results_by_text: "Reduce Results By",
    show_filter_query_text: "Show Filter Query",
    cluster_cloud_text : "CLUSTER CLOUD",
    download_text:"Download",
    applied_tags_text: "Applied Tags ",
    selected_tags_text: "Selected Tags",
    selected_project_text: "Selected Project",
    prior_art_search_title_text: "Prior-Art Search",
    dashboards_header_text: "Dashboards",
    no_dashboards_text : "No Dashboards Available.",
    downloads_header_text: "Downloads",
    no_downloads_text: "No Downloads Available.",
    projects_back_button_text: "Projects",
    queries_text: "QUERIES",
    workfiles_text: "WORKFILES",
    new_text: "New",
    edit_text: "Edit Chart",
    delete_text: "Delete",
    share_text: "Share",
    select_all_text: "Select All",
    copy_text: "Copy",
    combine_text: "Combine",
    id_text: "Id:",
    hits_text: "Hits:",
    run_on_newtab_text: "Run on Newtab",
    run_text: "Run",
    query_text: "Query:",
    filters_text: "Filters",
    filters_title_text: "Filters",
    document_filter_text: "Document",
    taxonomy_text: "Taxonomy",
    date_text: "Date:",
    project_name_text: "Project Name",
    do_you_want_to_delete_the_project_text: "Do you want to delete the project",
    select_user_text: "Select User",
    selected_query_id_text: "Selected Query ID",
    selected_query_ids_text: "Selected Query IDs",
    query_name_text: "Query Name",
    do_you_want_to_delete_queries_text: "Do you want to delete queries",
    query_title_text: "Query Title",
    options_text: "Options",
    existing_text: "Existing",
    new_project_text: "New Project",
    clone_text: "Clone",
    query_history_text: "Query History",
    selected_queries_text: "Selected Queries",
    active_alerts_text: "Active Alerts",
    name_text: "Name",
    description_text: "Description",
    alert_type_text: "Alert type",
    no_alerts_available_text: "No Alerts Available.",
    created_date_text: "Created Date",
    expiration_date: "Expiration Date",
    notification_method_text: "Notification Method",
    email_text : "Email",
    frequency_text: "Frequency",
    do_you_want_to_delete_the_alert_text: "Do you want to delete the alert",
    alert_text : "Alert",
    describe_the_alert_text: "Describe the alert",
    notification_method_and_frequency_text : "Notification method and frequency",
    every_text: "Every",
    week_on_text: "Week(s) on",
    month_on_text: "Month(s) on",
    on_updates_text : "On updates",
    select_alert_type_text : "* Select alert type",
    new_publication_text : "New publication",
    new_family_text : "New family",
    new_assignee_text: "New assignee",
    new_forward_citations_text : "New forward citations",
    new_etst_declaration_text: "New ETSI declaration",
    new_fda_patents_text : "New FDA patents",
    reassignment_alerts_text : "Reassignment alerts",
    reassignment_text: "Reassignment",
    assignor_text : "Assignor",
    assignee_text : "Assignee",
    legal_status_based_text: "Legal status based",
    grants_text : "Grants",
    lapses_text: "Lapses",
    expired_patents_text : "Expired patents",
    withdrawn_patents_text : "Withdrawn patents",
    opposition_text: "Opposition",
    term_extensions_text: "Term extensions",
    active_text: "Active",
    inactive_text: "Inactive",
    notification_method_text: "Notification Method",
    filter_text: "Filter",
    receipent_list_text : "Receipent List",
    no_workfiles_created_text: "No workfiles created/assigned",
    workfile_name_text: "Workfile Name",
    do_you_want_to_delete_the_workfile_text: "Do you want to delete the workfile",
    highlighting_text: "Highlighting",
    collapse_all_text: "Collapse All",
    expand_all_text: "Expand All",
    failed_text : "Failed",
    select_taxonomy_text : "Select Taxonomy",
    new_dashboard_text: "New Dashboard",
    existing_dashboard_text : "Existing Dashboard",
    dashboard_name_text: "Dashboard Name",
    deselect_all_text: "Deselect All",
    select_all_text: "Select All",
    cluster_cloud_taxonomy_text: "ClusterCloud Taxonomy",
    cpc_taxonomy_text: "CPC Taxonomy",
    add_new_category_text: "Add new category",
    file_name_text: "File Name",
    select_fields_text: "Select Fields",
    reduce_by_content_text : "Reduce By",
    document_text : "Document",
    select_report_format_text: "Select Report Format",
    excel_text : "Excel",
    csv_text: "CSV",
    taxonomy_sheet_text: "Taxonomy Sheet",
    highlighting_options_text : "Highlighting Options",
    highlight_search_terms_text: "Highlight Search-Terms",
    highlight_custom_terms_text: "Highlight Custom-Terms",
    export_highlighting_fields_text : "Export Highlighting Fields",
    existing_workfile_text : "Existing Workfile",
    new_workfile_text : "New Workfile",
    important_semantics_text: "Important Semantics",
    citations_text : "Citations",
    family_members_text : "Family Members",
    validate_patent_number_text : "Validate Patent Number",
    interesting_startups_text: "Interesting Startups",
    investors_list_text: "Investors List:",
    sort_text : "Sort",
    year_text: "Year",
    no_of_patents_text : "No Of Patents",
    foundation_year_text : "Foundation Year:",
    investors_text: "Investors",
    enter_the_reference_patent_number_text: "Enter the reference patent number",
    submit_text: "Submit",
    no_reference_patent_selected_text: "No reference patent selected",
    hidden_citations_selected_text : "Hidden Citations selected",
    patents_selected_text: "Patents selected",
    select_all_from_current_page_text: "Select All from Current Page",
    exists_in_the_history_text: "Exists in the history",
    and_processing_text: "And Processing",
    meeting_code_text: "Meeting Code:",
    type_text : "Type:",
    meeting_start_text: "Meeting Start:",
    meeting_end_text : "Meeting End:",
    tdoc_snippet_text : "Tdoc Snippet:",
    source_text: "Source:",
    agent_item_text: "Agent Item:",
    agent_item_description_text: "Agent Item Description:",
    release_text: "Release:",
    version_text: "Version:",
    cr_category_text: "CR Category:",
    pte_text: "PTE",
    patent_term_extended_text: "Patent Term Extended",
    opp_text: "OPP",
    opposition_filed_text: "Opposition Filed",
    fda_text: "FDA",
    fda_orange_book_patent_text: "FDA-Orange Book Patent",
    rsg_text: "RSG",
    reassignement_text: "Reassignement",
    max_rating_text: "Max. Rating",
    weightage_text: "Weightage",
    add_user_text: "Add user",
    index_based_text: "Index based",
    from_text: "From",
    assign_to_text: "Assign to",
    action_text: "Action",
    do_you_want_auto_update_alert_for_this_workfile_text: "Do you want auto update alert for this workfile?",
    yes_text: "Yes",
    no_text : "No",
    export_png_text: "Export PNG",
    low_text: "Low",
    medium_text: "Medium",
    high_text: "High",
    export_csv_text : "Export CSV",
    publication_title_text: "Publication",
    delete_patents_text: "Delete Patents",
    are_you_sure_you_want_to_delete_text: "Are you sure you want to delete",
    something_went_wrong_text: "Something went wrong.",
    claim_elements_text: "Claim Elements",
    opp_re_exam_text: "OPP/RE-EXAM/APPL",
    search_terms_text: "Search Terms",
    custom_terms_text: "Custom Terms",
    original_text : "Original",
    languages_text: "Languages",
    priority_date_text: "Priority Date",
    priority_no_text: "Priority No",
    application_date_text: "Application Date",
    application_no_text: "Application No",
    publication_date_text: "Publication Date",
    patent_status_text: "Patent Status",
    expected_expiry: "Expected Expiry",
    family_id_text: "Family Id",
    original_assignee_text: "Original Assignee",
    current_assignee_text: "Current Assignee",
    norm_assignee_text: "Norm. Assignee",
    original_assignee_normalized_text: "Original Assignee Normalized",
    inventor_text : "Inventor",
    examiner_text: "Examiner",
    agent_correspondent_text : "Agent / Correspondent",
    patent_citations_text: "Patent Citations",
    forward_citations_text: "Forward Citations",
    non_patent_citations_text : "Non-Patent Citations",
    reassignment_history_text : "Reassignment History",
    legal_events_text: "Legal Events",
    family_events_chart_text : "Family Events Chart",
    external_links_text : "External Links",
    espacenet_text: "Espacenet",
    google_patents_text: "Google Patents",
    assigned_users_text: "Assigned Users",
    abstract_text: "Abstract",
    dolcera_rank_text: "Dolcera Rank",
    extended_members_text : "Extended Family Members",
    x_citations_text: "X Citations",
    y_citations_text : "Y Citations",
    class_codes_text : "Class Codes",
    etsi_integration_text : "ETSI Integration:",
    basis_patent_text: "Basis Patent :",
    declaration_company_text : "Declaration Company :",
    declaration_date_text: "Declaration Date :",
    cited_patent_text: "Cited Patent",
    filing_date_text : "Filing Date",
    title_text : "Title",
    isld_number_text: "ISLD number :",
    declaration_year_text: "Declaration Year :",
    etsi_standards_text: "ETSI Standards :",
    project_text : "Project :",
    images_text: "Images",
    claims_text: "Claims",
    family_to_family_citations_text : "Family To Family Citations",
    cited_by_examiner_text: "* Cited by examiner,",
    cited_by_third_party_text: "† Cited by third party,",
    cited_by_applicant_text : "⟲ Cited by applicant,",
    self_citation_text: "⥀ Self citation,",
    family_to_family_citation_text : "‡ Family to family citation",
    recorded_date_text: "Recorded Date",
    assignors_text: "Assignors",
    code_text: "Code",
    event_text: "Event",
    conveyance_text: "Conveyance",
    active_countries_text: "Active Countries",
    inactive_countries_text: "Inactive Countries",
    patent_not_available_text: "Patent Not Available",
    similarity_score_text: "Similarity Score",
    match_text: "% match",
    hidden_citations_text : "Hidden Citations",
    connections_text: "Connections",
    etsi_basis_text: "ETSI Basis",
    comments_text: "Comments",
    rating_text: "Rating",
    you_rated_it_text: "You rated it",
    kindly_set_ratings_parameters_in_preferences_text : "Kindly set ratings parameters in preferences",
    done_text: "Done",
    tags_text: "Tags",
    merge_chart_text: "Merge Chart",
    apply_text: "Apply",
    contact_text: "Contact",
    back_soon_text : "We’ll be back soon!",
    the_pcs_team_text : "— The PCS Team",
    sorry_for_the_inconvenience_text: "Sorry for the inconvenience but we’re performing some maintenance at the moment.",
    we_back_online_shortly_text: "We’ll be back online shortly!",
    check_un_claimed_subject_matter_text: "Check Un-Claimed Subject Matter",
    recent_text: "Recent",
    check_claim_correlation_text: "Check Claim Correlation",
    export_to_sheet_text: "Export To Sheet",
    export_to_dashboard_text: "Export To Dashboard",
    both_citations_text : "Both Citations",
    backward_citations_text : "Backward Citations",
    your_search_did_not_match_any_documents_text : "Your search didn't match any documents",
    try_different_keywords_text : "Try different keywords",
    make_sure_that_all_words_are_spelled_correctly_text: "Make sure that all words are spelled correctly",
    workfile_does_not_exist_check_workfile_id_text: "Workfile does not exist. Check workfile id",
    you_do_not_have_an_access_to_this_workfile_text: "You do not have an access to this workfile",
    alert_name_text: "Alert Name",
    dashboard_description_text: "Dashboard Description",
    category_name_text : "Category Name",
    please_enter_file_name_text : "Please Enter File Name",
    search_fields_text: "Search Fields...",
    mm_dd_yyyy_text: "mm/dd/yyyy",
    add_concepts_text : "Add Concepts...",
    enter_own_concept_text: "Enter own concept",
    date_range_text: "Date Range",
    important_concepts_text: "Important Concepts",
    cpc_codes_text: "CPC Codes:",
    enter_cpc_code_text: "Enter CPC Code",
    enter_country_code_text: "Enter Country Code",
    enter_rating_tag_text: "Enter rating tag",
    search_workfile_text: "Search Workfile...",
    add_description_text: "Add Description...",
    documents_title_text: "DOCUMENTS",
    score_text: "Score",
    documents_text: "Documents",
    next_text: "Next",
    prev_text: "Prev",
    filter_search_text : "Filter Search",
    filter_search_placeholder : "Filter Search...!", 
    chart_filters_text: "Filters",
    infotiles: "infotiles",
    filters: "filters",
    synonyms: "synonyms",
    wordforms: "wordforms",
    connected_data_text : "Connected Data",
    field_prefrences_data_text : "Field Preferences",
    settings_data_text:"Settings",
    group_sort_text:"Group Sort",
    master_data_text:"Master Data",
    indexed_text: "Indexed:",
    not_supported_text: "Not Supported:",
    select_x_axis_text:"Select X axis",
    select_y_axis_text: "Select Y axis",
    select_z_axis_text: "Select Z axis",
    filter_query_text : "Filter Query",
    add_to_search_text : "Add To Search",
    enterprise_search_title_text: "Enterprise Search",
    filter_fields_text: "Filter fields",
    x_axis_text: "X-Axis",
    y_axis_text: "Y-Axis",
    z_axis_text: "z-Axis",
    filter_search_placeholder : "Filter Search...!",
    chart_info_title_text: 'Title',
    chart_info_title_text_zh:'Title (Chinese)',
    technologies_path_text: 'Technologies Path',
    equipment_path_text: 'Equipment Path',
    website_text: "Website",
    files_uploader_text: "Files Uploader",
    product_reports_text: "Product Reports",
    enter_website_url_here_text : "Enter website URL here...",
    depth_text: "Depth:",
    select_directory_text: "Select Directory",
    enter_directory_text: "Enter Directory..!",
    drag_and_drop_files_text: "Drag and drop files",
    there_is_no_files_to_indexing_text: "There are no files available for indexing.",
    there_is_no_services_to_indexing_text: "There are no services available for indexing.",
    reports_compare_text: "Compare",
    reports_customize_text: "Customize",
    reports_timeline_text: "Timeline",
    reports_competitor_text: "Competitor",
    reports_companies_text: "Select Companies",
    edit_document_text: "Edit Document",
    add_document_text:"Add Document",
    restore_to_default_text:"Restore to Default",
    copied_image_url_enter_here_placeholder: "Copied image URL enter or pasted Here...!",
    load_more:'Load More..',
    first_name_text: "First Name",
    last_name_text: "Last Name",
    user_email_text: "E-mail",
    password_text: "Password",
    role_text: "Role",
    users_text: "Users",
    new_user_text: "New User",
    reset_password: "Reset Password",
    snippet_text:"Snippet",
    abstract_text:"Abstract",
    summery_text:"Summary",
    landing_page_header_text:"Get started with the powerful, modern enterprise search experience",
    partnership_with: "Partnership with",
    all_documents_text: "Explore all documents",
    save_as_template:"Save as Template",
    add_node_text: "Add Node",
    no_search_data_section: "No matches found for your search.",
    view_all_text:"View All",
    data_connectors_text:"Data connectors",
    social_connectors_text:"Social connectors",
    cloud_connectors_text:"Cloud connectors",
    my_projects_text:"My Projects",
    no_connectors_available: "No Connectors Available",
    import_text: "Import",
    no_document_details: "No Document Details",
    user_manual_text: "User manual",
    upload_files_text: "Upload Files",
    drag_and_drop_text: "Drag and Drop your files in this area (or)",
    sm_upload_files_text: "Upload files from computer",
    sm_drag_and_drop_text: "Or drag and drop here (.xlsx, .xls, zip up to 50MB)",
    browse_files_text : "Browse Files",
    export_to_pdf_text:"Export to PDF",
    export_to_sheet_text:"Export to Sheet",
    close_text: "Close",
    workfile_editable:"Edit",
    specifcation_title_text: "Specification Version Tracking",
    section_number_text: "Section Number",
    tdoc_number_text: "TDOC Number",
    tdoc_title_text: "Section Title",
    start_version_text: "Start Version",
    end_version_text : "End Version",
    cr_category_text : "CR Category",
    three_gpp_details_text: "3GPP details",
    top_three_gpp_contributors_text: " Top 3GPP contributors",
    serial_number_text : "S.no",
    no_of_contributions_text : "No of contributions",
    top_etsi_declaring_companies : " Top ETSI Declaring companies",
    company_text: 'Company',
    preferences_info_field: "Information",
    preferences_filter_url:"Filter URL",
    details_view_text: "View",
    group_sort_data_title_text: "No Group Sort Options Selected",
    original_document_text: "Original Documents",
    add_note_text: "Add Note",
    add_alert_text: "Add Alert",
    select_range_text: "Select Range",
    document_review_text: "Document Review",
    update_review_text: "Update Review",
    delete_query_text: "Delete query",
    browse_text : "Browse",
    export_to_project_title: "Export to Selected Project",
    move_to_project_title: "Move to Selected Project",
    new_project_text: "New Project",
    existing_project_text : "Existing Project",
    customize_fields_text : "Customize Fields",
    customize_project_text: "Customize Project",
    review_patent_text : "Reviewed Patents",
    under_review_patent_text : "Under Review",
    created_by_text:"Created By",
    no_files_available_text: "No new notifications at this time.",
    no_fields_for_mapping_text: "Currently, there are no fields available for mapping.",
    please_review_config_fields_text: " Please review the mapping configuration to ensure all necessary fields are defined.",
    edit_mapping_text: "Edit Mapping",
    no_sheet_identified_for_mapping_text: "Unable to Identify Datasheet Type",
    please_review_sheet_text: "We couldn't recognize the type of the datasheet you uploaded. Please ensure it is in the correct format and try uploading again.",
    granted_claims_text : "Granted Claim",
    application_claims_text : "Application Claim",
    preferences_edit_source : "Edit Source",
    save_query_to_stored_search_title: "Save query to stored search",
    new_search_option_text: "New Search",
    existing_search_option_text: "Existing Search"
}